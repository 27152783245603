<!-- 用户端小红书信息设置 -->
<template>
  <div class="personalInfo">
    <el-row>
      <el-input clearable v-arrange-url v-model="homepage" size="mini" style="width: 80%"
        placeholder="请填写小红书账号链接"></el-input>
    </el-row>
    <div class="body">
      <span>注:请添加小红书个人信息截图 我们会在三个工作日之内审核</span>
      <el-upload :class="{ hide: hideUploadAdd }" style="margin-top: 10px" size="mini" :limit="5" action="/api/cos/upload"
        list-type="picture-card" :on-success="successChange" :on-change="handleAddChange"
        :on-preview="handlePictureCardPreview" :headers="uploadHeaders" :data="{ folder: 'social' }"
        :on-remove="handleRemove">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" :preview-src-list="fileList" alt="" />
      </el-dialog>
    </div>
    <el-row style="text-align: center; margin-top: 10px">
      <el-button size="mini" @click="changepersonal">提交审核信息</el-button>
    </el-row>
  </div>
</template>

<script>
import { sysUserSocial, upsysUserSocial } from "@/api/user";
import { arrangeUrl } from "@/config/directives"
export default {
  directives: {
    arrangeUrl
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      hideUploadAdd: false,
      uploadHeaders: { "x-auth-token": localStorage.getItem("token") },
      fileList: [],
      homepage: "",
    };
  },
  created() { },
  mounted() { },
  watch: {},
  computed: {},
  methods: {
    handleRemove(file, fileList) {
      this.fileList = this.fileList.filter(function (item) {
        return item !== file.response;
      });

      if (fileList.length <= 4) {
        this.hideUploadAdd = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
        // 发现某一张超过大小就从列表移除
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
      }

      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 5) {
        this.hideUploadAdd = true;
      }
    },
    async successChange(response, file, fileList) {
      this.fileList.push(response.data);
    },
    async changepersonal() {
      let submit;
      let params;
      if (this.data[this.element.status] === null) {
        submit = sysUserSocial;
        params = {
          homepage: this.homepage,
          homepageScreenshots: this.fileList,
          type: this.element.type,
        };
      } else {
        submit = upsysUserSocial;
        params = {
          homepage: this.homepage,
          homepageScreenshots: this.fileList,
          type: this.element.type,
          id: this.data.id,
        };
      }

      await submit(params).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "成功修改用户信息",
            type: "success",
          });
          this.$emit("submit");
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

.personalInfo {
  text-align: start;

  ::v-deep .el-card {
    height: 100%;
  }

  ::v-deep .el-button {
    color: white;
    // font-weight: bold;
    background-color: #5874ef;
  }

  font-size: 0.8rem;

  .el-row {
    margin-bottom: 10px;
  }

  .body {
    & span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e74343;
    }
  }
}
</style>
<style lang="scss" >
.el-upload--picture-card {
  height: 6rem !important;
  width: 6rem;

  & i {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 6rem !important;
  height: 6rem !important;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>