<!--左侧二级菜单 -->
<template>
  <div class="leftside">
    <!-- <div style="height: 56px;line-height:56px; padding:0 23px;font-size: 18px">
      <i :class="showSide? 'el-icon-s-unfold':'el-icon-s-fold'" @click="collapseChange" />
    </div> -->
    <el-menu
      ref="menu"
      :default-active="defaultItem"
      text-color="#000"
      active-text-color="#fff"
      router
      :collapse="showSide"
      @select="handleSelect"
    >
      <!-- <el-menu-item v-for="(item,key) in menuDatas" :key="key" :index="item.path">
        <i :class="item.icon" />
        <span slot="title" style="margin-left:11px">{{ item.title }}</span>

      </el-menu-item> -->
      <div v-for="(item, key) in menuDatas" :key="key">
        <el-submenu v-if="item.children" :index="item.path">
          <template slot="title">
            <i :class="item.icon" />
            <!-- <div class="deco"></div> -->
            <span slot="title" style="margin-left: 11px; font-size: 0.9rem">{{
              item.name
            }}</span>
          </template>
          <el-menu-item
            v-for="(child, i) in item.children"
            :key="i"
            :index="child.path"
          >
            <!-- <i :class="child.icon" /> -->
            <span
              slot="title"
              style="font-weight: normal; margin-left: 10px; font-size: 0.8rem"
              >{{ child.title }}</span
            >
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :index="item.path">
          <i :class="item.icon" />
          <span slot="title" style="margin-left: 11px; font-size: 0.9rem">{{
            item.title
          }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Name",
  components: {},
  props: {
    activeIndex: {
      type: String,
      default: "",
    },
    menuDatas: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      data: "",
      defaultItem: this.$route.path,
      showSide: false,
    };
  },
  watch: {
    menuDatas: {
      handler(newval, oldval) {
        this.init();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      if (this.menuDatas.length === 0) {
        this.$emit("sideWidth", "0");
      } else {
        this.$emit("sideWidth", "250px");
        this.showSide = false;
        this.$nextTick(() => {
          this.$refs.menu.activeIndex = this.activeIndex;
        });
      }
    },
    collapseChange() {
      this.showSide = !this.showSide;
      if (this.showSide) {
        this.$emit("sideWidth", "62px");
      } else {
        this.$emit("sideWidth", "250px");
      }
    },
    handleSelect(key, keyPath) {
      //  if (this.activeItem === key) {
      //      console.log(key)// TODO 不执行操作
      //  } else {
      //      this.activeItem = key
      //      console.log(key, keyPath)
      //  }
    },
  },
};
</script>

<style lang='scss' scoped>
.leftside {
  text-align: left;
  height: calc(100%);
  .el-menu {
    border-right: 0;
    height: 100%;
    position: relative;
  }
  .deco {
    width: 3px;
    height: 16px;
    position: absolute;
    margin-top: 22px;
    background: #618cef;
  }
  .el-menu-item {
    // font-size: 16px;
    font-weight: bold;
  }

  .el-submenu {
    // font-size: 16px;
    font-weight: bold;
  }

  .el-menu-item.is-active {
    background-color: #5874ef !important;
    // border-right: 2px solid;
  }

  .el-menu--collapse .el-submenu__title span {
    display: none;
  }

  /*隐藏 > */
  .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  }

  .el-menu-item:hover {
    outline: 0 !important;
    color: #bbb !important;
    background: linear-gradient(270deg, #4c62e3 0%, #4c62e3 100%) !important;
  }
}
</style>
