<!--
 * @Author: dingguowei
 * @Date: 2023-06-28 10:46:38
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-07 15:04:20
-->
<!-- 用户弹出框 -->
<template>
  <div>
    <el-popover placement="top-start" width="200" trigger="hover" popper-class="down-popover">
      <el-image slot="reference" style="
          width: 36px;
          height: 36px;
          background-color: #fff;
          border-radius: 50%;
        " :src="userInfo.avatar ? userInfo.avatar : '/images/user.png'" fit="fill">
      </el-image>
      <div slot="" class="popover">
        <div class="head">
          <el-image slot="reference" style="
              width: 40px;
              height: 40px;
              background-color: #fff;
              border-radius: 50%;
            " :src="userInfo.avatar ? userInfo.avatar : '/images/user.png'" fit="fill"></el-image>
          <div class="info">
            <p>{{ userInfo.account }}</p>
            <p>{{ userInfo.userType === 2 ? "普通用户" : "会员" }}</p>
          </div>
        </div>
        <div class="body">
          <div class="item" v-for="(item, index) in settingData" :key="index" @click="clickfun(item)">
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.name }}</span>
            <div v-if="item.fieId" :style="[
              { color: userData[item.fieId] !== null ? '#000' : '#bbb' },
            ]" style="      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;">
              <span>
                {{
                  infoStatus(userData[item.fieId], userData[item.status])
                }}</span>
              <i class="icon-you iconfont"></i>
            </div>
          </div>
        </div>
      </div>
    </el-popover>
    <!-- 小红书弹窗 -->
    <el-dialog title="小红书账号信息填写" :visible.sync="showredBook" width="30%" :append-to-body="true">
      <redbook-dialog @submit="submit" :element="activeele" :data="userData" />
    </el-dialog>
    <!-- 支付宝弹窗 -->
    <el-dialog title="支付宝账号信息填写" :visible.sync="showZfb" width="30%" :append-to-body="true">
      <zfb-dialog @submit="submit" :element="activeele" :data="userData" />
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { userInfozbget } from "@/api/user";
import redbookDialog from "@/components/user/redbookDialog.vue";
import zfbDialog from "@/components/user/zfbDialog.vue";
export default {
  components: {
    redbookDialog,
    zfbDialog
  },
  data() {
    return {
      userData: {},
      settingData: [
        {
          name: "小红书",
          icon: "icon-xiaohongshu",
          type: 0,
          fieId: "socialNickName",
          status: "verifyStatus",
          methodName: 'changeRedBook'
        },
        {
          name: "支付宝",
          icon: "icon-zhifubaozhifu",
          fieId: "alipayAccount",
          methodName: 'changeZfb'
        },
        {
          name: "微信",
          icon: "icon-big-WeChat",
          fieId: "wxpayAccount",
        },
        {
          name: "个人信息",
          icon: "icon-shezhi2",
          methodName: 'personalInfo'
        },
        {
          name: "退出登录",
          icon: "icon-tuichu3",
          methodName: 'exit'
        },
      ],
      activeele: {},
      showredBook: false,
      showZfb: false
    };
  },
  created() { },
  mounted() {
    this.getuserInfozbg();
  },
  watch: {},
  computed: {
    userInfo() {
      return store.state.user.userInfo;
    },
    infoStatus() {
      return function (val, statu) {
        if (!val) {
          if (statu === null || statu === undefined) {
            return "未设置";
          } else if (statu === 2) {
            return "审核被拒绝";
          } else if (statu === 0) {
            return "审核中";
          }
        } else {
          return val;
        }
      };
    },
  },
  methods: {
    async getuserInfozbg() {
      await userInfozbget().then((res) => {
        this.userData = res.data;
        store.commit('user/CHANGE_userInfo', res.data)
      });
    },
    clickfun(val) {
      this.activeele = val
      if (val.methodName) {
        this[val.methodName](val)
      }
    },
    //小红书弹窗
    changeRedBook(val) {
      if (this.userData[val.fieId] === null) {
        this.showredBook = true;
      }

    },
    // 支付宝弹窗
    changeZfb() {
      this.showZfb = true
    },
    // 个人信息
    personalInfo() {
      router.push({
        name: "个人信息",
      });
    },
    // 退出登录
    exit() {
      localStorage.removeItem("token");
      localStorage.removeItem("userStatus");
      sessionStorage.removeItem("fistItem");
      this.$router.replace("loginPage");
    },
    async submit() {
      this.showredBook = false;
      this.showZfb = false
      await this.getuserInfozbg()
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-image {
  display: flex;
}

.head {
  user-select: text;
  background-image: url("../assets/user/popover.png");
  background-size: 100% 100%;
  border-radius: 10px 10px 0 0;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .info {
    color: white;
    padding-left: 20px;

    & :first-child {
      font-size: 1rem;
      font-weight: bold;
    }

    & :last-child {
      font-size: 0.8rem;
    }
  }
}

.body {
  .item {
    user-select: text;
    padding: 4px 20px;
    display: flex;
    align-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & i {
      font-size: 24px;
    }

    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      margin-left: 10px;

    }

    & div {
      margin-left: auto;
      display: flex;
      align-items: center;
      overflow: hidden;

      & i {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
</style>
<style>
.down-popover {
  padding: 0;
  min-width: 250px;
}
</style>