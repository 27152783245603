<!--
 * @Author: dingguowei
 * @Date: 2023-06-30 16:36:41
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-03 13:59:04
-->
<!-- 引导文档 -->

<template>
  <div class="guidedoc" v-if="userType">
    <el-collapse-transition>
      <div v-show="show">
        <!-- 用户反馈 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="用户反馈"
          placement="left"
        >
          <div class="iconitem" style="margin-bottom: 10px">
            <i class="iconfont icon-fabu icon"></i>
          </div>
        </el-tooltip>
        <!-- 产品介绍 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="产品介绍"
          placement="left"
        >
          <el-popover
            placement="left"
            width="400"
            trigger="click"
            popper-class="popperOptions"
          >
            <div class="popbody">
              <div class="head">
                文档中心
                <i class="iconfont icon-yonghu"></i>
              </div>
              <div class="body">
                <div class="title">热门文档</div>
                <div class="doc">
                  <div
                    class="item"
                    v-for="(item, index) in docDatas"
                    :key="index"
                  >
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div slot="reference" class="iconitem" style="margin-bottom: 10px">
              <i class="iconfont icon-a-chanpinjieshao1x"></i>
            </div>
          </el-popover>
        </el-tooltip>
      </div>
    </el-collapse-transition>
    <!-- 关闭开启 -->
    <el-tooltip
      class="item"
      effect="dark"
      :content="show ? '关闭' : '展开'"
      placement="left"
    >
      <div class="iconitem" @click="show = !show">
        <i class="iconfont icon-quanbu"></i>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import store from "@/store";
import { sysDocument } from "@/api/user";
export default {
  components: {},
  data() {
    return {
      show: false,
      docDatas: [],
    };
  },
  created() {},
  mounted() {},
  watch: {
    show: {
      handler() {
        this.initdoc();
      },
    },
  },
  computed: {
    userType() {
      if (
        store.state.user.userInfo.userType === 2 ||
        store.state.user.userInfo.userType === 3
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 初始化文档内容
    async initdoc() {
      await sysDocument().then((res) => {
        this.docDatas = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.guidedoc {
  width: 40px;
  z-index: 1;
  .iconitem {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    .icon {
      font-size: 20px;
    }
  }
}
</style>
<style lang="scss">
.popperOptions[x-placement^="left"] .popper__arrow::after {
  // border-left-color: #f62121;
}
.popperOptions {
  // background-color: #ea4e3d;
  user-select: text;
  font-size: 18px;
  padding: 0px;
  .popbody {
    .head {
      background-color: #1c96cb;
      font-size: 0.9rem;
      padding: 10px;
      color: rgb(255, 255, 255);
      & i {
        margin-left: 10px;
      }
    }
    .body {
      min-height: 160px;
      max-height: 250px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      .title {
        color: #999;
        font-size: 0.8rem;
      }
      .doc {
        margin-top: 10px;
        padding: 10px;
        flex: 1;
        border: 1px solid #d1d1d1;
        font-size: 0.8rem;
        .item {
          line-height: 160%;

          & span:hover {
            cursor: pointer;
            color: #1c96cb;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>