<!--
 * @Author: dingguowei
 * @Date: 2023-07-06 15:02:22
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-07 18:23:37
-->
<!-- 支付宝弹窗 -->
<template>
    <div class="zfb">
        <el-form :model="zfbData" status-icon :rules="rules" ref="zfb" label-width="120px" class="demo-ruleForm">
            <el-form-item label="支付宝姓名" prop="name">
                <el-input size="mini" v-model="zfbData.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="支付宝账号" prop="account">
                <el-input size="mini" v-model="zfbData.account" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="真实姓名" prop="realName">
                <el-input size="mini" v-model="zfbData.realName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="身份证信息" prop="idCard">
                <el-input size="mini" v-model="zfbData.idCard" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="微信支付账号" prop="wxpayAccount">
                <el-input size="mini" v-model="zfbData.wxpayAccount" autocomplete="off"></el-input>
            </el-form-item>
            <el-button size="mini" type="primary" @click="submitForm('zfb')">提交</el-button>

        </el-form>
    </div>
</template>

<script>
import { updatezbWallet } from "@/api/user"
import store from "@/store";
export default {
    components: {},
    data() {
        var checkZfb = (rule, value, callback) => {
            var phoneRegex = /^1\d{10}$/;
            // 邮箱地址验证
            var emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
            // 用户ID验证
            var idRegex = /^[a-zA-Z]\w{5,19}$/;
            if (phoneRegex.test(value)) {
                callback();
            } else if (emailRegex.test(value)) {
                callback();
            } else if (idRegex.test(value)) {
                callback();
            } else {
                callback(new Error('支付宝账号格式不正确'));
            }
        };
        var checkidcard = (rule, value, callback) => {
            function validateIDCard(idCard) {
                // 正则表达式
                var reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;

                if (reg.test(idCard) === false) {
                    return false;
                }

                // 获取身份证号的前17位
                var code = idCard.substring(0, 17);

                // 加权因子
                var factor = [
                    7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2
                ];

                // 校验位
                var parity = [
                    '1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'
                ];

                var sum = 0;
                var ai = 0;
                var wi = 0;

                for (var i = 0; i < 17; i++) {
                    ai = code[i];
                    wi = factor[i];
                    sum += ai * wi;
                }

                var last = parity[sum % 11];
                if (last !== idCard[17].toUpperCase()) {
                    return false;
                }

                return true;
            }
            if (validateIDCard(value)) {
                callback()
            } else {
                callback(new Error('身份证格式不正确'));
            }
        };
        var checkrealName = (rule, value, callback) => {
            let checkName = /^[\u4e00-\u9fa5]{2,4}$/
            if (checkName.test(value)) {
                callback()
            } else {
                callback(new Error('仅支持中文姓名（2-5汉字）'));
            }
        }
        return {

            zfbData: {
                name: '',
                account: '',
                realName: '',
                idCard: '',
                wxpayAccount: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                account: [
                    { validator: checkZfb, trigger: 'blur' },
                    { required: true, message: '请输入账号', trigger: 'blur' },
                ],
                idCard: [
                    { validator: checkidcard, trigger: 'blur' },
                    { required: true, message: '请输入身份证信息', trigger: 'blur' },
                ],
                realName: [
                    { validator: checkrealName, trigger: 'blur' },
                    { required: true, message: '请输入真实姓名', trigger: 'blur' },
                ]
            }
        }
    },
    created() { },
    mounted() { },
    watch: {
        userInfo: {
            handler(newvalue) {
                this.zfbData.idCard = newvalue.idCard
                this.zfbData.account = newvalue.alipayAccount
                this.zfbData.alipayName = newvalue.name
                this.zfbData.realName = newvalue.realName
                this.zfbData.wxpayAccount = newvalue.wxpayAccount
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        userInfo() {
            return store.state.user.userInfo;
        },
    },
    methods: {
        // 提交
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.changeZfb()
                } else {

                    return false;
                }
            });
        },
        async changeZfb() {
            await updatezbWallet({
                alipayAccount: this.zfbData.account,
                alipayName: this.zfbData.name,
                idCard: this.zfbData.idCard,
                realName: this.zfbData.realName,
                wxpayAccount: this.zfbData.wxpayAccount
            }).then(res => {
                if (res.code === 1000) {
                    this.$emit("submit");
                    this.$message({
                        message: "支付宝信息填写成功",
                        type: "success",
                    });

                } else {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped></style>